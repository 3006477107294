<template>
  <v-app>
    <div class="topBar">
      <div class="container">
        <Button :download-nick="nick" :software-title="soft" :text="page.downloadTitle" class="mx-2" />
        <Button :download-nick="nick" :software-title="soft" text="Sign Up" class="bg-blueButton" />
      </div>
    </div>
    <v-app-bar flat>
      <v-row no-gutters class="align-center container mx-auto">
        <v-icon
          icon="$menu"
          class="v-col-2 d-lg-none d-flex justify-center"
          color="#015e72"
          size="large"
          aria-label="menu"
          @click.stop="drawer = !drawer"
        ></v-icon>

        <v-col cols="6" md="7" lg="3" xl="2" class="d-flex justify-center">
          <ImageLogo />
        </v-col>

        <v-col cols="7" md="7" lg="7" xl="8" class="d-none d-lg-block">
          <Menu :menu="page.menu" class="d-flex text-uppercase" :nav="true" width="100%" />
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary location="top" class="d-lg-none d-flex" absolute>
      <Menu :menu="page.menu" class="mt-4 text-uppercase" />
    </v-navigation-drawer>

    <v-main :class="route.path === '/' ? 'pt-0 mx-auto' : 'container pt-0 mx-auto'">
      <breadcrumbs
        v-if="page.breadcrumbs && page.breadcrumbs.length > 0"
        :items="page.breadcrumbs"
        devider-icon="$chevronRight"
        devider-color="breadcrumbs"
        class="py-0 mt-2 ma-0"
      />

      <aside v-if="page.blocks?.AboveContent && aboveContentShow" class="my-1">
        <Region :key="page.uri" region="AboveContent" :blocks="page.blocks.AboveContent">
          <template #blocks>
            <span v-for="(block, n) in page.blocks.AboveContent" :key="n">
              <Block
                v-if="show[n]"
                :id="`AboveContent-${n}`"
                :type="isUzi(block?.type) ? 'UziQuery' : block?.type"
                :title="block?.title"
                :props="block?.props"
                v-bind="$attrs"
                :class="block?.type === 'MainBanner' ? 'container mb-2' : 'mb-2'"
                @nodata="nodata(n)"
              ></Block>
            </span>
          </template>
        </Region>
      </aside>

      <v-row v-if="page.blocks?.RightSide" no-gutters>
        <v-col cols="12" md="9">
          <article class="ma-2 pa-3">
            <Article :key="page.uri"></Article>

            <Button
              :download-nick="nick"
              :software-title="soft"
              :text="page.downloadTitle"
              class="d-block ma-auto pa-2 mt-2 text-uppercase font-weight-bold"
            />
          </article>
          <aside v-if="page.blocks?.BelowContent" class="blockDesign mr-2 mt-2 pa-5">
            <Region :key="page.uri" region="BelowContent" :blocks="page.blocks.BelowContent" />
          </aside>
        </v-col>

        <aside v-if="page.blocks?.RightSide" class="v-col-12 v-col-md-3">
          <Region
            :key="page.uri"
            region="RightSide"
            :blocks="page.blocks.RightSide"
            class="blockDesign rightBlock mb-2"
            @noregion="page.hideRegion"
          />
        </aside>
      </v-row>
      <template v-else>
        <article class="ma-2 pa-3">
          <Article :key="page.uri"></Article>
          <Button
            :download-nick="nick"
            :software-title="soft"
            :text="page.downloadTitle"
            class="d-block ma-auto pa-2 mt-2 text-uppercase font-weight-bold"
          />
        </article>
        <aside v-if="page.blocks?.BelowContent" class="blockDesign">
          <Region :key="page.uri" region="BelowContent" :blocks="page.blocks.BelowContent" />
        </aside>
      </template>
      <footer v-if="page.blocks?.Footer" class="d-block">
        <Region :key="page.uri" region="Footer" :blocks="page.blocks.Footer" class="container" />
      </footer>
    </v-main>
    <ScrollToTop rounded="lg" />
  </v-app>
</template>
<script setup>
import { isUzi } from "@/lib/tools.js";

const page = inject("page");
const nick = ref(unref(page)?.rels?.downloadNick);
const soft = ref(unref(page)?.rels?.softwareTitle);
const drawer = ref(false);
const route = useRoute();
watchEffect(() => {
  nick.value = unref(page)?.rels?.downloadNick;
  soft.value = unref(page)?.rels?.softwareTitle;
});
const show = unref(page)?.blocks?.AboveContent
  ? new Array(unref(page).blocks.AboveContent.length).fill(ref(true))
  : false;
const aboveContentShow = ref(true);
const nodata = n => {
  show[n].value = false;
  if (show.find(e => e.value === true) === -1) aboveContentShow.value = false;
};
const fab = ref(false);
</script>
